import { createApp } from "petite-vue";
import Swiper from "swiper";
import { Autoplay, Keyboard, Navigation } from "swiper/modules";
import.meta.glob(["@images/**"]);
import.meta.env.DEV && import("@styles/main.sass");
import SimpleLightbox from "simple-lightbox";

interface Header {
  opened: boolean;
  handleOpenedHeader(state: boolean): void;
}

const headerEl = document.querySelector("[data-header]"),
  navEl = document.querySelector(".HeaderNavigation"),
  headerScope: Header = {
    opened: false,
    handleOpenedHeader(state) {
      document.body.classList.toggle("u-hiddenOverflow", state);
      if (navEl) {
        navEl.classList.toggle("is-opened", state);
      }
      headerScope.opened = state;

      // Aktualizace burgeru
      const burgerButton = document.querySelector(".HeaderBurger");
      if (burgerButton) {
        if (state) {
          burgerButton.classList.add("is-opened");
        } else {
          burgerButton.classList.remove("is-opened");
        }
      }
    },
  };

headerEl && createApp(headerScope).mount(headerEl);

function normalizeUrl(url) {
  // Remove trailing slash, except when the URL is the homepage
  return url.endsWith("/") && url.length > 1 ? url.slice(0, -1) : url;
}

function updateActiveLink() {
  const menuLinks = document.querySelectorAll(".HeaderNavigation a");
  const currentUrl = normalizeUrl(window.location.href);
  const bodyClassList = document.body.classList;

  menuLinks.forEach((link) => {
    if (link instanceof HTMLAnchorElement) {  // Ensure the element is an anchor element
      link.classList.remove("active");

      // Exclude language switcher links
      if (!link.classList.contains('wpml-ls-flag') && !link.closest('.wpml-ls-item')) {
        if (normalizeUrl(link.href) === currentUrl) {
          link.classList.add("active");
        } else if (bodyClassList.contains('single-post')) {
          // If the page has the 'single-post' class, set the Blog link as active
          if (normalizeUrl(link.href).includes('/blog') || normalizeUrl(link.href).includes('/articles')) {
            link.classList.add("active");
          }
        } else if (bodyClassList.contains('single-testimonial')) {
          // If the page has the 'single-testimonial' class, set the Case Studies link as active
          if (normalizeUrl(link.href).includes('/pripadove-studie') || normalizeUrl(link.href).includes('/case-studies')) {
            link.classList.add("active");
          }
        } else if (currentUrl.includes('produkt') || currentUrl.includes('product')) {
          // If the URL contains the word 'produkt' or 'product', set the Products and Services link as active
          if (normalizeUrl(link.href).includes('/produkt') || normalizeUrl(link.href).includes('/product')) {
            link.classList.add("active");
          }
        } else if (bodyClassList.contains('page-template-template-service')) {
          // If the page has the 'page-template-template-service' class, set the Products and Services link as active
          if (normalizeUrl(link.href).includes('/produkty-a-sluzby') || normalizeUrl(link.href).includes('/products-and-services')) {
            link.classList.add("active");
          }
        }
      }
    }
  });
}

updateActiveLink();

//-------------------------------------------------------------------
document.addEventListener("click", function (event) {
  if (!headerEl.contains(event.target) && headerScope.opened) {
    headerScope.handleOpenedHeader(false);
  }
});

document.addEventListener("keydown", function (event) {
  if (event.key === "Escape" && headerScope.opened) {
    headerScope.handleOpenedHeader(false);
  }
});

//------------------------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {
  const swiperContainer = document.getElementById(
    "swiper-studies"
  ) as HTMLElement;

  new Swiper(swiperContainer, {
    cssMode: true,
    modules: [Navigation, Keyboard],
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
});

(() => {
  const swiperLogoContainer = document.getElementById(
    "swiper-loga"
  ) as HTMLElement;

  new Swiper(swiperLogoContainer, {
    cssMode: true,
    modules: [Autoplay],
    slidesPerView: 2,
    spaceBetween: 30,
    autoplay: {
      delay: 2000,
    },
    loop: true,
    slidesPerGroup: 2,
    speed: 3000,
    breakpoints: {
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1000: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
    },
  });
})();

//-----------------------------------------------------------------------Galerie v příspěvcích

(() => {
  const galleryElement = document.querySelector(".wp-block-gallery");
  if (!galleryElement) return;

  new SimpleLightbox({
    elements: ".wp-block-gallery .wp-block-image > a",
  });
})();

//-----------------------------------------------------------------------Galerie single produkt

(() => {
  const galleryElement = document.querySelector(".Gallery");
  if (!galleryElement) return;

  new SimpleLightbox({
    elements: ".Gallery .ImageCard > a",
  });
})();


